import { render, staticRenderFns } from "./ImprovedSeedRegistrationSteps.vue?vue&type=template&id=f5938a30&scoped=true&"
import script from "./ImprovedSeedRegistrationSteps.vue?vue&type=script&lang=ts&"
export * from "./ImprovedSeedRegistrationSteps.vue?vue&type=script&lang=ts&"
import style0 from "./ImprovedSeedRegistrationSteps.vue?vue&type=style&index=0&id=f5938a30&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5938a30",
  null
  
)

export default component.exports